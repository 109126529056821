import axios from 'axios'

const state = {
    branch_outcome: {},
    branch_outcomes: {},
    branch_outcome_errors: {},
    branch_outcome_drugs: {},
    branch_outcomes_loading: false,
    branch_outcome_type: "to",
}

const getters = {
    branch_outcome: state => state.branch_outcome,
    branch_outcomes: state => state.branch_outcomes,
    branch_outcomes_loading: state => state.branch_outcomes_loading,
    branch_outcome_errors: state => state.branch_outcome_errors,
    branch_outcome_type: state => state.branch_outcome_type,
    branch_outcome_drugs: state => state.branch_outcome_drugs,
}

const mutations = {
    setBranchOutcome(state, branch_outcome) {
        state.branch_outcome = branch_outcome
    },
    setBranchOutcomeDrugs(state, branch_outcome_drugs) {
        state.branch_outcome_drugs = branch_outcome_drugs
    },
    setBranchOutcomes(state, branch_outcomes) {
        state.branch_outcomes = branch_outcomes
    },
    setBranchOutcomesLoading(state, branch_outcomes_loading) {
        state.branch_outcomes_loading = branch_outcomes_loading
    },
    setBranchOutcomeErrors(state, errors) {
        state.branch_outcome_errors = errors
    },
    setBranchOutcomeType(state, branch_outcome_type) {
        state.branch_outcome_type = branch_outcome_type
    },
}

const actions = {
    async getBranchOutcomes({commit, getters}, params) {
        if (getters.permissions?.includes('branch_outcomes.index')) {
            commit('setBranchOutcomeErrors', {})
            commit('setBranchOutcomesLoading', true)
            try {
                let response = await axios.get('/api/branch-outcomes', {params})
                commit('setBranchOutcomes', response.data)
                commit('setBranchOutcomesLoading', false)


            } catch (error) {
                commit('setBranchOutcomeErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchOutcome({commit}, id) {
        commit('setBranchOutcomeErrors', {})
        commit('setBranchOutcomesLoading', true)
        try {
            let response = await axios.get(`/api/branch-outcomes/${id}/show`)
            commit('setBranchOutcome', response.data)
            commit('setBranchOutcomesLoading', false)
        } catch (error) {
            commit('setBranchOutcomeErrors', error.response.data.errors)
            return false
        }
    },
    async getBranchOutcomeDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('branch_outcomes.drugs')) {
            commit('setBranchOutcomeErrors', {})
            commit('setBranchOutcomesLoading', true)
            try {
                let response = await axios.get(`/api/branch-outcomes/${params.id}/drugs`, {params})
                commit('setBranchOutcomeDrugs', response.data)
                commit('setBranchOutcomesLoading', false)
            } catch (error) {
                commit('setBranchOutcomeErrors', error.response.data.errors)
                return false
            }
        }
    },

    async storeBranchOutcome({commit}, data) {
        commit('setBranchOutcomeErrors', {})
        commit('setBranchOutcomesLoading', true)
        try {
            let response = await axios.post('/api/branch-outcomes/store', data)
            commit('setBranchOutcomesLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchOutcomeErrors', error.response.data.errors)
            commit('setBranchOutcomesLoading', false)

            return false
        }
    },

    async updateBranchOutcome({commit, dispatch}, data) {
        commit('setBranchOutcomeErrors', {})
        commit('setBranchOutcomesLoading', true)
        try {
            let response = await axios.put(`/api/branch-outcomes/${data.id}/update`, data)
            dispatch('getBranchOutcome', data.id)
            commit('setBranchOutcomesLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchOutcomeErrors', error.response.data.errors)
            commit('setBranchOutcomesLoading', false)
            return false
        }
    },
    async updateBranchOutcome2({commit, dispatch}, data) {
        commit('setBranchOutcomeErrors', {})
        commit('setBranchOutcomesLoading', true)
        try {
            let response = await axios.put(`/api/branch-outcomes/${data.id}/update2`, data)
            dispatch('getBranchOutcome', data.id)
            commit('setBranchOutcomesLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchOutcomeErrors', error.response.data.errors)
            commit('setBranchOutcomesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}