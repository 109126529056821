import axios from 'axios'

const state = {
    user: {},
    user_drugs: {},
    users: {},
    user_errors: {},
    users_loading: false,
}

const getters = {
    user: state => state.user,
    user_drugs: state => state.user_drugs,
    users: state => state.users,
    users_loading: state => state.users_loading,
    user_errors: state => state.user_errors,
}

const mutations = {
    setUser(state, user) {
        state.user = user
    },
    setUserDrugs(state, user_drugs) {
        state.user_drugs = user_drugs
    },
    setUsers(state, users) {
        state.users = users
    },
    setUsersLoading(state, users_loading) {
        state.users_loading = users_loading
    },
    setUserErrors(state, errors) {
        state.user_errors = errors
    },
}

const actions = {
    async getUsers({commit}, params) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get('/api/users', {params})
            commit('setUsers', response.data)
            commit('setUsersLoading', false)


        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async getUser({commit}, id) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get(`/api/users/${id}/show`)
            commit('setUser', response.data)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async destroyUser({commit}, id) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            await axios.delete(`/api/users/${id}/destroy`)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async getUserDrugs({commit}, params) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.get(`/api/users/${params.id}/drugs`, {params})
            commit('setUserDrugs', response.data)
            commit('setUsersLoading', false)
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            return false
        }
    },
    async storeUser({commit}, data) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.post('/api/users/store', data)
            commit('setUsersLoading', false)
            return response.data
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            commit('setUsersLoading', false)

            return false
        }
    },

    async updateUser({commit}, data) {
        commit('setUserErrors', {})
        commit('setUsersLoading', true)
        try {
            let response = await axios.put(`/api/users/${data.id}/update`, data)
            commit('setUsersLoading', false)
            return response.data
        } catch (error) {
            commit('setUserErrors', error.response.data.errors)
            commit('setUsersLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}