import axios from 'axios'

const state = {
    branch: {},
    branches: {},
    branches_all: {},
    branch_errors: {},
    branch_drugs: {},
    branch_shelves: {},
    branch_users: {},
    branch_sales: {},
    branches_loading: false,
    shelf_number: null,
}

const getters = {
    branch: state => state.branch,
    branches: state => state.branches,
    branches_all: state => state.branches_all,
    branches_loading: state => state.branches_loading,
    branch_errors: state => state.branch_errors,
    branch_drugs: state => state.branch_drugs,
    branch_shelves: state => state.branch_shelves,
    branch_sales: state => state.branch_sales,
    branch_users: state => state.branch_users,
    shelf_number: state => state.shelf_number,
}

const mutations = {
    setBranch(state, branch) {
        state.branch = branch
    },
    setBranchDrugs(state, branch_drugs) {
        state.branch_drugs = branch_drugs
    },
    setBranchSales(state, branch_sales) {
        state.branch_sales = branch_sales
    },
    setBranchUsers(state, branch_users) {
        state.branch_users = branch_users
    },
    setBranches(state, branches) {
        state.branches = branches
    },
    setAllBranches(state, branches_all) {
        state.branches_all = branches_all
    },
    setBranchesLoading(state, branches_loading) {
        state.branches_loading = branches_loading
    },
    setBranchErrors(state, errors) {
        state.branch_errors = errors
    },
    setBranchShelves(state, branch_shelves) {
        state.branch_shelves = branch_shelves
    },
    setShelfNumber(state, shelf_number) {
        state.shelf_number = shelf_number
    },
}

const actions = {
    async getBranches({commit, getters}, params) {
        if (getters.permissions?.includes('branches.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get('/api/branches', {params})
                commit('setBranches', response.data)
                commit('setBranchesLoading', false)


            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getAllBranches({commit, getters}, params) {
        if (getters.permissions?.includes('branches.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get('/api/branches/all', {params})
                commit('setAllBranches', response.data)
                commit('setBranchesLoading', false)


            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getFromBranchDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('branches.branch_drugs')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get(`/api/branches/${params.from}/${params.id}/drugs`, {params})
                commit('setBranchDrugs', response.data)
                commit('setBranchesLoading', false)
            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranch({commit}, id) {
        commit('setBranchErrors', {})
        commit('setBranchesLoading', true)
        try {
            let response = await axios.get(`/api/branches/${id}/show`)
            commit('setBranch', response.data)
            commit('setBranchesLoading', false)
        } catch (error) {
            commit('setBranchErrors', error.response.data.errors)
            return false
        }
    },
    async getBranchDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('branch.drugs.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get(`/api/branches/${params.id}/drugs`, {params})
                commit('setBranchDrugs', response.data)
                commit('setBranchesLoading', false)
            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchDrugShelves({commit, getters}, params) {
        if (getters.permissions?.includes('branch.drugs.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get(`/api/branches/${params.id}/drugs/shelves/${params.shelf_number}`, {params})
                commit('setBranchDrugs', response.data)
                commit('setBranchesLoading', false)
            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchAllDrugs({commit, getters}, params) {
        if (getters.permissions?.includes('branch.drugs.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get(`/api/branches/${params.id}/drugs/all`, {params})
                commit('setBranchDrugs', response.data)
                commit('setBranchesLoading', false)
            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchUsers({commit, getters}, params) {
        if (getters.permissions?.includes('branches.users.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get(`/api/branches/${params.id}/users`, {params})
                commit('setBranchUsers', response.data)
                commit('setBranchesLoading', false)
            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchShelves({commit, getters}, params) {
        if (getters.permissions?.includes('branches.users.index')) {
            commit('setBranchErrors', {})
            commit('setBranchesLoading', true)
            try {
                let response = await axios.get(`/api/branches/${params.id}/shelves`, {params})
                commit('setBranchShelves', response.data)
                commit('setBranchesLoading', false)
            } catch (error) {
                commit('setBranchErrors', error.response.data.errors)
                return false
            }
        }
    },
    async getBranchSales({commit}, params) {
        commit('setBranchErrors', {})
        commit('setBranchesLoading', true)
        try {
            let response = await axios.get(`/api/sales`, {params})
            commit('setBranchSales', response.data)
            commit('setBranchesLoading', false)
        } catch (error) {
            commit('setBranchErrors', error.response.data.errors)
            return false
        }
    },
    async getBranchSalesByDate({commit}, params) {
        commit('setBranchErrors', {})
        commit('setBranchesLoading', true)
        try {
            let response = await axios.get(`/api/sales/by-date`, {params})
            commit('setBranchSales', response.data)
            commit('setBranchesLoading', false)
        } catch (error) {
            commit('setBranchErrors', error.response.data.errors)
            return false
        }
    },
    async getBranchTodaysSales({commit}, params) {
        commit('setBranchErrors', {})
        commit('setBranchesLoading', true)
        try {
            let response = await axios.get(`/api/sales/todays`, {params})
            commit('setBranchSales', response.data)
            commit('setBranchesLoading', false)
        } catch (error) {
            commit('setBranchErrors', error.response.data.errors)
            return false
        }
    },
    async storeBranch({commit}, data) {
        commit('setBranchErrors', {})
        commit('setBranchesLoading', true)
        try {
            let response = await axios.post('/api/branches/store', data)
            commit('setBranchesLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchErrors', error.response.data.errors)
            commit('setBranchesLoading', false)

            return false
        }
    },

    async updateBranch({commit}, data) {
        commit('setBranchErrors', {})
        commit('setBranchesLoading', true)
        try {
            let response = await axios.put(`/api/branches/${data.id}/update`, data)
            commit('setBranchesLoading', false)
            return response.data
        } catch (error) {
            commit('setBranchErrors', error.response.data.errors)
            commit('setBranchesLoading', false)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}